export const environment = {
  production: true,
  apibaseUrl: 'https://admin.dev.omniorder.com.au',
  signUpSignInAuthority:"https://omniorderb2cdev.b2clogin.com/omniorderb2cdev.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_DEV",
  forgotPasswordAuthority:"https://omniorderb2cdev.b2clogindev.com/omniorderb2cdev.onmicrosoft.com/B2C_1A_PASSWORDRESET_DEV",
  editProfileAuthority:"https://omniorderb2cdev.b2clogin.com/omniorderb2cdev.onmicrosoft.com/B2C_1A_PROFILEEDIT_DEV",
  authorityDomain:"omniorderb2cdev.b2clogin.com",
  clientId:"93be4a9e-0ade-479a-a6b7-b682205407b8",
  scope:"https://omniorderb2cdev.onmicrosoft.com/OmniOrderAPI/access_as_user",
  postLogoutRedirectUri:"https://www.omniorder.com.au",
};
