<div class="platform-management">

  
  <div class="section health-checks">
    <h3>Platform Health Checks</h3>
    <pre>{{ platformHealthChecks | json }}</pre>
  </div>
  
  <div class="section cache-management">
    <h3>Cache Management</h3>
    
    <div class="cache-performance">
      <h4>Cache Performance</h4>
      <pre>{{ this.cachePerformance | json }}</pre>
    </div>
    
    <button class="clear-cache-btn" (click)="clearCache()">Clear Cache</button>
  </div>
</div>


