import { Component, OnInit } from '@angular/core';
import { AdminService } from '../shared/services/Admin.Service';

@Component({
  selector: 'app-platform-management',
  templateUrl: './platform-management.component.html',
  styleUrls: ['./platform-management.component.css']
})
export class PlatformManagementComponent implements OnInit {
  platformHealthChecks: any;
  cachePerformance: any;

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.getPlatformHealthChecks();
    this.getCacheInfo();
  }

  getPlatformHealthChecks(): void {
    this.adminService.getPlatformHealthChecks().subscribe(
      data => {
        this.platformHealthChecks = data;
      },
      error => {
        console.error('Error fetching platform health checks', error);
      }
    );
  }

  getCacheInfo(): void {
    this.adminService.getCacheInfo().subscribe(
      data => {
        this.cachePerformance = data;
      },
      error => {
        console.error('Error fetching cache info', error);
      }
    );
  }

  clearCache(): void {
    this.adminService.clearCache().subscribe(
      success => {
        if (success) {
          console.log('Cache cleared successfully');
        } else {
          console.error('Failed to clear cache');
        }
      },
      error => {
        console.error('Error clearing cache', error);
      }
    );
  }
}
